class imageCarousel {
	constructor(container) {
		this._container = container;
		this._index = 0;
		this._children = Array.from(this._container.querySelectorAll("figure"));
		this.count = this._children.length;
		this.currentImage = this._children[0];
	}

	show(index = 0) {
		if (typeof index != "number") {
			throw new TypeError("The `index` argument must be a number");
		}

		this._index = Math.max(0, Math.min(index, this.count - 1));
		const currentImage = this._children[this._index];

		window.requestAnimationFrame(() => {
			this._children.forEach((child) => {
				child.style.opacity = "0";
				child.classList.remove("is-active");
			});

			currentImage.style.opacity = "1";
			currentImage.classList.add("is-active");

			const img = currentImage.querySelector("img");
			if (img) {
				this._loadImage(img);
			}
		})

		this.currentImage = currentImage;
		this.preload(4);
	}

	prev () {
		this.show(this._mod(this._index - 1, this.count));
	}

	next () {
		this.show(this._mod(this._index + 1, this.count));
	}

	preload (count = 4) {
		if (typeof count != "number") {
			throw new TypeError("The `count` argument must be a number");
		}

		const totalCount = count * 2;
		const startIndex = this._index - count;

		new Array(totalCount + 1)
			.fill(startIndex)
			.map((i, idx) => this._mod(i + idx, this.count))
			.sort()
			.map((i) => this._children[i].querySelector("[data-src]"))
			.filter((img) => img)
			.forEach(this._loadImage);
	}

	_loadImage (img) {
		if (img.src) return;

		if (img.hasAttribute("data-srcset")) {
			img.srcset = img.getAttribute("data-srcset");
			img.removeAttribute("data-srcset");
		}

		img.src = img.getAttribute("data-src");
		img.removeAttribute("data-src");
	}

	_mod (n, m) {
		return (n % m + m) % m;
	}
}
